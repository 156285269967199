@font-face {
  font-family: "Avenir Black";
  src: url("../fonts/Avenir-Black.eot");
  src: url("../fonts/Avenir-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir-Black.woff") format("woff"), url("../fonts/Avenir-Black.ttf") format("truetype"), url("../fonts/Avenir-Black.svg#Avenir-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Light";
  src: url("../fonts/Avenir-Light.eot");
  src: url("../fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Avenir-Light.woff") format("woff"), url("../fonts/Avenir-Light.ttf") format("truetype"), url("../fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

.grow {
  animation: grow 1s;
}

@-webkit-keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

body::selection {
  background: #d71b36;
}

.corp-font {
  font-family: "Avenir Black", Arial;
}

.corp-font-light {
  font-family: "Avenir Light", Arial;
}

/* Colors */
.red {
  background: #d71b36;
}

.lightgray {
  background: #a3a7a9;
}

.gray {
  background: #797d82;
}

.darkgray {
  background: #484c55;
}

.black {
  background: #000;
}

.errorred {
  background: red;
}

.orange {
  background: #ff4e00;
}

.blue {
  background: #33647f;
}

.blue-link {
  color: #33647f;
}

.blue-link a {
  color: #33647f;
}

.slate {
  background: #415464;
}

.slate-alpha {
  background: rgba(65, 84, 100, 0.95);
}

.stone {
  background: #768693;
}

/* Alerts */
.alert-info {
  background: #33647f;
  border-color: #1d3a49;
  color: #fff;
}

.alert-warning {
  background: #ff4e00;
  border-color: #992e00;
  color: #fff;
}

.alert-danger {
  background: red;
  border-color: #881123;
  color: #fff;
}

/* Headings */
h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: #d71b36;
}

/* Type */
a {
  color: #d71b36;
  text-decoration: underline;
}

a:hover {
  color: #000;
}

a:focus {
  color: #000;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}

/* Tables */
.table-default thead th {
  border-color: #484c55;
}

.table-default td,
.table-default th {
  border-color: #484c55;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-striped tbody tr.table-active:nth-of-type(odd) {
  background-color: rgba(51, 100, 127, 0.2);
}

.table-hover tbody tr:nth-of-type(odd):hover {
  background-color: rgba(51, 100, 127, 0.2);
}

.table-hover tbody tr:hover {
  background-color: rgba(51, 100, 127, 0.2);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(51, 100, 127, 0.2);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(51, 100, 127, 0.2);
}

.table-active:hover,
.table-active > th:hover,
.table-active > td:hover {
  background-color: rgba(51, 100, 127, 0.2);
}

.thead-inverse th {
  font-weight: normal;
}

td.details-control {
  position: relative;
}

td.details-control::after {
  background-color: #fff;
  border: 2px solid #33647f;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: '+';
  color: #33647f;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 10px;
  left: 5px;
  width: 20px;
}

tr.shown td.details-control::after {
  border-color: #d71b36;
  color: #d71b36;
  content: '-';
  line-height: 18px;
}

.child-row-table {
  margin-bottom: 0 !important;
  padding-left: 50px;
}

.child-row-table tr {
  background-color: transparent !important;
}

.child-row-table tr:first-of-type td {
  border-top: none;
}

.dataTables_length select {
  background: url(../images/icon-arrow-down.svg) right 10px center no-repeat #fff !important;
  width: 70px !important;
}

table.table-filterable th {
  position: relative;
}

table.table-filterable th:focus {
  outline: none;
}

table.table-filterable th i.fa.fa-filter {
  color: #b3b3b3;
  cursor: pointer;
  float: left;
  left: 0;
  line-height: 38px;
  padding: 0 5px 0 0;
  position: absolute;
  z-index: 3;
}

table.table-filterable th i.fa.fa-filter.selected {
  color: #000;
}

table.table-filterable th i.fa.fa-filter.applied {
  color: #d71b36;
}

table.table-filterable th span {
  line-height: 38px;
  margin-left: 6px;
}

table.table-filterable th .form-control.filter-search {
  background: #fff url(http://i.stack.imgur.com/mJotv.gif) no-repeat right -10px center;
  display: none;
  float: left;
  font-weight: normal;
  margin-left: 6px;
  padding-right: 17px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}

table.table-filterable th .form-control.filter-search.x {
  background-position: right 5px center;
}

table.table-filterable th .form-control.filter-search.onX {
  cursor: pointer;
}

table.table-filterable th .form-control.filter-search::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

/* Tabs */
.nav-tabs .nav-item a.nav-link {
  background-color: #fff;
  border-color: #ccc #ccc #ddd;
  text-decoration: none;
  font-weight: bold;
}

.nav-tabs .nav-item a.nav-link:hover {
  background-color: #eee;
  border-color: #ccc #ccc #ddd;
}

.nav-tabs .nav-item a.nav-link:focus, .nav-tabs .nav-item a.nav-link.active {
  background-color: #eee;
  border-color: #ccc #ccc transparent;
  color: #000;
}

.nav-tabs .nav-item a.nav-link:focus:hover, .nav-tabs .nav-item a.nav-link.active:hover {
  color: #000;
}

.nav-tabs .nav-item a.nav-link.disabled {
  background-color: #fff;
  border-color: #ccc #ccc #ddd;
  color: #797d82;
  font-weight: normal;
}

.nav-tabs .nav-item a.nav-link.disabled:hover {
  border-color: #ccc #ccc #ddd;
}

.tab-content {
  background-color: #eee;
  border: 1px solid;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-color: transparent #ccc #ccc;
  padding: 2rem 1rem;
}

.tabs-alternate .nav-tabs .nav-item a.nav-link {
  background-color: #fff;
}

.tabs-alternate .tab-content {
  background-color: #fff;
}

/* Buttons */
.btn {
  display: inline-block;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 16px;
  transition: .5s;
  text-decoration: none;
}

.btn:focus {
  outline: none;
}

.btn.mobile {
  padding: 11px 30px;
}

.btn-default {
  background-color: #d71b36;
  border: 1px solid #d71b36;
  color: #fff;
}

.btn-default:focus {
  background-color: #AD0025;
  color: #fff;
}

.btn-default:hover {
  background-color: #5b0b17;
  border: 1px solid #5b0b17;
  color: #fff;
}

.btn-default-inv {
  background-color: #d71b36;
  border: 1px solid #d71b36;
  color: #fff;
}

.btn-default-inv:hover {
  background-color: #fff;
  color: #d71b36;
}

.btn-primary {
  background-color: #33647f;
  border-color: #33647f;
}

.btn-primary:hover {
  background-color: #1d3a49;
  border-color: #1d3a49;
  color: #fff;
}

.btn-primary-inv {
  background-color: #33647f;
  border-color: #33647f;
  color: #fff;
}

.btn-primary-inv:hover {
  background-color: #fff;
  color: #33647f;
}

.btn-warning {
  background-color: #ff4e00;
  border-color: #ff4e00;
}

.btn-warning:hover {
  background-color: #992e00;
  border-color: #992e00;
}

.btn-warning-inv {
  background-color: #ff4e00;
  border-color: #ff4e00;
  color: #fff;
}

.btn-warning-inv:hover {
  background-color: #fff;
  color: #ff4e00;
}

.btn-inverse {
  border: 1px solid #d71b36;
  background-color: #fff;
  color: #d71b36;
}

.btn-inverse:hover {
  border: 1px solid #d71b36;
  background-color: #d71b36;
  color: #fff;
}

.btn-achromatic {
  background-color: #000;
  color: #fff;
}

.btn-achromatic:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
}

.btn-disabled {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  color: #797d82;
}

.btn-disabled:focus, .btn-disabled:hover {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #797d82;
  cursor: not-allowed;
}

.btn-link {
  color: #d71b36;
}

.btn-link:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #000;
}

.btn-link-visited {
  color: #33647f;
  text-decoration: underline;
}

.btn-link-disabled {
  color: #a3a7a9;
  cursor: not-allowed;
}

.btn-link-disabled:hover {
  color: #a3a7a9;
  text-decoration: none;
}

/* Form Elements */
.form-control {
  border-color: silver;
  color: #000;
}

.form-control:focus {
  border-color: #000;
  color: #000;
}

.form-control.mobile {
  min-height: 44px;
}

.form-control.mobile option {
  padding: 11px 0;
}

fieldset {
  padding: 5px;
}

fieldset:focus {
  outline: none;
  background: #efefef;
}

select.form-control:not([size]):not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/icon-arrow-down.svg") right 17px center no-repeat #fff;
  border: 1px solid silver;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #000;
  height: 40px;
  width: 287px;
  padding: 0 17px;
}

select.form-control:not([size]):not([multiple]):focus {
  border-color: #000;
  color: #000;
}

select.form-control:not([size]):not([multiple]).mobile {
  height: 44px;
}

input[type="file"] {
  padding: 10px;
  background: #efefef;
}

.text-muted {
  color: #484c55 !important;
}

.form-check-label {
  padding-left: 0;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}

.form-check-label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form-check-label .form-indicator {
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 2px;
  left: 0;
  background: #e6e6e6;
  border: 1px solid #ccc;
}

.form-check-label .form-indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .form-indicator {
  background: #d71b36;
}

.form-check-label input:checked ~ .form-indicator:after {
  display: block;
}

.form-check-label input:not([disabled]):focus ~ .form-indicator,
.form-check-label input:not([disabled]):hover ~ .form-indicator {
  background: #ccc;
}

.form-check-label.form-radio .form-indicator {
  border-radius: 50%;
}

.form-check-label.form-radio .form-indicator:after {
  left: 5px;
  top: 5px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #fff;
}

.form-check .form-indicator:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-check-label:hover input:not([disabled]):checked ~ .form-indicator,
.form-check-label input:checked:focus ~ .form-indicator {
  background: #AD0025;
}

.form-mobile {
  padding-top: 6px;
  padding-bottom: 10px;
  padding-left: 40px;
}

.form-mobile .form-indicator {
  width: 30px;
  height: 30px;
}

.form-radio.form-mobile .form-indicator:after {
  width: 13px;
  height: 13px;
  top: 7px;
  left: 7px;
  border-radius: -1px;
}

.form-checkbox.form-mobile .form-indicator:after {
  width: 8px;
  height: 15px;
  top: 5px;
  left: 10px;
}

/* Footer Overides */
footer.footer {
  border-top: 1px solid #a3a7a9;
  padding-top: 10px;
  font-size: 12px;
  padding-bottom: 65px;
}

footer.footer a {
  color: #484c55;
  text-decoration: none;
}

footer.footer a:hover {
  text-decoration: underline;
}

footer.footer .navbar-text {
  position: absolute;
  top: 15px;
  left: calc(50% - 99px);
}

footer.footer .break {
  margin-left: -64px;
  padding: 0 13%;
  text-align: center;
  position: absolute;
  top: 60px;
}

@media (max-width: 992px) {
  footer.footer {
    padding-bottom: 0;
  }
  footer.footer #navbarSupportedContent {
    display: flex;
    flex-direction: column;
  }
  footer.footer #navbarSupportedContent .navbar-text {
    order: 1;
    position: relative;
    top: inherit;
    left: inherit;
  }
  footer.footer #navbarSupportedContent .break {
    position: relative;
    top: inherit;
    display: flex;
    order: 1;
    text-align: left;
    padding: 0;
    margin-left: 0;
  }
}

.modal .form-group {
  float: left;
  width: 100%;
}

.modal .form-group label {
  float: left;
  width: 35%;
}

.modal .form-group .form-text {
  float: left;
  width: 60%;
}

.modal .form-group input[type="file"] {
  background: #FFF;
  border: 1px solid #ccc;
  width: 60%;
}

.modal .modal-footer {
  clear: both;
}

.slick-next,
.slick-prev {
  height: 40px;
  width: 40px;
  z-index: 1;
}

.slick-next::before,
.slick-prev::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 40px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.slick-next {
  right: 10px;
}

.slick-next::before {
  content: '\f054';
}

.slick-prev {
  left: 10px;
}

.slick-prev::before {
  content: '\f053';
}

.slick-slide {
  position: relative;
}

.slick-dots {
  bottom: initial;
}

.slick-dots li {
  height: 40px;
  margin: 0;
  width: 40px;
}

.slick-dots li button {
  height: 40px;
  width: 40px;
}

.slick-dots li button::before {
  color: #d71b36;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  opacity: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  width: 40px;
}

.slick-dots li.slick-active button::before {
  color: #000;
  font-size: 30px;
  opacity: 1;
}

/* Accordion */
.card .card-header {
  position: relative;
  background: none;
}

.card .card-header a {
  text-decoration: none;
  display: block;
}

.card .card-header a:after {
  content: '+';
  font-family: OpenSans-Light;
  font-size: 48px;
  color: #484C55;
  line-height: 48px;
  position: absolute;
  top: 0;
  right: 5px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  transform: rotate(45deg);
}

.card .card-header a.collapsed:after {
  transform: rotate(0deg);
}

.minimalist-accordion .card {
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  margin-bottom: 0;
}

.minimalist-accordion .card.active {
  background-color: rgba(163, 167, 169, 0.1);
}

.minimalist-accordion .card.active .card-header {
  border: none;
}

.minimalist-accordion .card-header {
  border-bottom: 2px solid rgba(163, 167, 169, 0.3);
  margin: 0 auto;
  width: 75%;
}

.minimalist-accordion .card-header a {
  color: #484c55;
  line-height: 30px;
}

.minimalist-accordion .card-block {
  color: #484c55;
  margin: 0 auto;
  width: 75%;
}

/* Progress */
.progress {
  height: 1.5rem;
}

.progress .progress-bar {
  background-color: #d71b36;
  height: auto;
}

/* Drag & Drop Control */
.dd-container {
  border: 2px solid #a3a7a9;
  border-radius: 5px;
  height: 150px;
  list-style-type: none;
  padding: 20px;
  width: 100%;
}

.dd-container li {
  float: left;
  height: 100px;
  text-align: center;
  width: 20%;
}

.dd-container li.placeHolder div {
  background-color: #fff;
  border: dashed 2px #33647f;
}

.dd-container li div {
  background-color: #E0E0E0;
  border: solid 2px #33647f;
  border-radius: 5px;
  display: inline-block;
  height: 110px;
  line-height: 110px;
  padding: 10px;
  text-align: center;
  width: 110px;
}

.dd-container li div span {
  color: #415464;
  display: inline-block;
  line-height: normal;
}

/* Dropdown Button */
.btn-with-dropdown {
  display: inline-block;
  position: relative;
}

.btn-with-dropdown > a {
  margin-left: 0;
  position: relative;
  z-index: 5;
}

.btn-with-dropdown > a::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  padding-left: 5px;
}

.btn-with-dropdown .btn-dropdown-container {
  background-color: #fff;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 55px;
  width: 150%;
  z-index: 4;
}

.btn-with-dropdown .btn-dropdown-container .btn-dropdown-option {
  border-bottom: 1px solid #efefef;
  padding: 10px;
}

.btn-with-dropdown .btn-dropdown-container .btn-dropdown-option:last-of-type {
  border-bottom: none;
}

.btn-with-dropdown .btn-dropdown-container .btn-dropdown-option a {
  text-decoration: none;
}

.btn-with-dropdown .btn-dropdown-container .btn-dropdown-option a:hover {
  text-decoration: underline;
}

.btn-with-dropdown:hover a {
  background-color: #fff;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #d71b36;
}

.btn-with-dropdown:hover .btn-dropdown-container {
  background: #fff;
  border: 1px solid #d71b36;
  border-radius: 5px;
  border-top-left-radius: 0;
  display: block;
  font-size: 12px;
}

.btn-with-dropdown.alternative > a {
  background: #33647f;
  border-color: #33647f;
}

.btn-with-dropdown.alternative > a svg {
  vertical-align: 1px;
  margin-left: 2px;
}

.btn-with-dropdown.alternative > a::after {
  display: none;
}

.btn-with-dropdown.alternative > a:hover {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  border-bottom: 1px solid #33647f;
}

.btn-with-dropdown.alternative .btn-dropdown-container {
  top: 58px;
  padding: 5px 0;
}

.btn-with-dropdown.alternative .btn-dropdown-container .btn-dropdown-option {
  border-bottom: none;
  padding: 0px 10px;
}

.btn-with-dropdown.alternative:hover a {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
}

.btn-with-dropdown.alternative:hover .btn-dropdown-container {
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  font-size: 15px;
}

.btn-with-dropdown.alternative:hover .btn-dropdown-container a {
  border: none;
  color: rgba(25, 25, 25, 0.95);
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  margin-bottom: 0;
}

.open > .dropdown-menu {
  display: block;
}

/* Dropzone */
.dropzone {
  border-radius: 5px;
  border-color: #ccc;
  background: transparent url(../images/icon-fileupload-fade.png) no-repeat center center;
  background-size: contain;
  padding: 40px;
  color: gray;
  text-shadow: 1px 1px 1px #fff;
}

/* Number Slider Control */
.slider .tooltip-inner {
  padding: 6px;
}

.slider .slider-selection {
  background: #a3a7a9;
}

.slider .slider-handle {
  background-color: #33647f;
  background-image: none;
}

/* Pagination */
.pagination .page-link {
  color: #d71b36;
  border-color: #d71b36;
  padding: 4px 20px;
  border-radius: 15px;
  margin-right: 5px;
  font-size: 14px;
  line-height: 1;
  box-sizing: content-box;
}

.pagination .page-link:last-child {
  border-radius: 15px;
}

.pagination .page-link:hover {
  background: #d71b36;
  color: #fff;
  text-decoration: none;
}

.pagination .page-item .page-link, .pagination .page-item .page-link:focus {
  outline: none;
}

.pagination .page-item.active .page-link {
  background: #d71b36;
  color: #fff;
  border-color: #d71b36;
  outline: none;
}

.pagination .page-item.active .page-link, .pagination .page-item.active .page-link:focus {
  outline: none;
}

.pagination .page-item.active:hover {
  background: #5b0b17;
}

.pagination.mobile-pagination .page-info {
  display: block;
  float: left;
  line-height: 46px;
  margin-right: 15px;
  position: relative;
}

.pagination.mobile-pagination .page-link {
  border-width: 2px;
  font-weight: bold;
  margin-right: 15px;
  padding: 14px 20px;
  text-transform: uppercase;
}

.pagination.mobile-pagination .page-link:last-child {
  border-radius: 5px;
}

.pagination.mobile-pagination .page-link.prev {
  margin-left: 20px;
  padding-left: 21px;
  padding-right: 25px;
  position: relative;
}

.pagination.mobile-pagination .page-link.prev::before {
  background: inherit;
  border: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0 4px 0 0;
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  content: "";
  height: 27px;
  left: -16px;
  position: absolute;
  top: 5px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  width: 27px;
  z-index: 1;
}

.pagination.mobile-pagination .page-link.next {
  padding-left: 25px;
  padding-right: 20px;
  position: relative;
}

.pagination.mobile-pagination .page-link.next::after {
  background: inherit;
  border: inherit;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 0 4px 0 0;
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  content: "";
  height: 27px;
  right: -16px;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  width: 27px;
  z-index: 1;
}

/* Tree Style Nav */
ul.nav.nav-list {
  display: block;
}

ul.nav.nav-list li {
  border-bottom: 1px solid #efefef;
  color: #000;
  display: block;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
}

ul.nav.nav-list li a {
  color: #484c55;
  text-decoration: none;
}

ul.nav.nav-list li a:hover {
  color: #d71b36;
}

ul.nav.nav-list li > a {
  color: #000;
  font-weight: 700;
  line-height: 30px;
}

ul.nav.nav-list li label {
  cursor: pointer;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
}

ul.nav.nav-list li label::after {
  color: #000;
  content: '+';
  cursor: pointer;
  font-family: OpenSans-Light, serif;
  font-size: 24px;
  line-height: 28px;
  margin-left: 8px;
  position: absolute;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

ul.nav.nav-list li label.collapsed::after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

ul.nav.nav-list li label:hover {
  color: #d71b36;
}

ul.nav.nav-list li label:hover::after {
  color: #d71b36;
}

ul.nav.nav-list.tree {
  display: none;
}

ul.nav.nav-list.tree li {
  border-bottom: none;
  margin-left: 20px;
}

ul.nav.nav-list.tree li > a {
  font-weight: normal;
}

/* Main Navigation Bar */
#mainnav {
  /* to fix style guide */
  height: 426px;
}

@media (min-width: 960px) {
  #mainnav {
    height: auto;
  }
}

.content-header {
  background-color: rgba(25, 25, 25, 0.95);
  height: 50px;
  left: 0;
  padding-left: 200px;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  z-index: 52;
  /* to fix style guide */
  position: relative;
}

.content-header .logo {
  left: 15px;
  position: absolute;
  top: 13px;
}

.content-header #block-icon-main-menu {
  display: inline-block;
  float: right;
}

.content-header #block-icon-main-menu ul.menu {
  display: none;
}

@media (min-width: 960px) {
  .content-header #block-icon-main-menu ul.menu {
    display: block;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
  }
}

.content-header #block-icon-main-menu ul.menu li {
  display: inline-block;
  padding-right: 20px;
  position: relative;
}

.content-header #block-icon-main-menu ul.menu li:hover > ul {
  display: block;
}

.content-header #block-icon-main-menu ul.menu li.has-submenu > a:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-left: 5px;
}

.content-header #block-icon-main-menu ul.menu li a {
  color: #fff;
  font-size: 0.9em;
  line-height: 50px;
  text-decoration: none;
  text-transform: uppercase;
}

.content-header #block-icon-main-menu ul.menu li a:hover, .content-header #block-icon-main-menu ul.menu li a.is-active {
  border-bottom: 2px solid #d71b36;
  color: #fff;
  padding-bottom: 2px;
}

.content-header #block-icon-main-menu ul.submenu {
  background-color: #000;
  border-top: 1px solid #a3a7a9;
  display: none;
  left: 0;
  padding: 0;
  position: absolute;
  top: 100%;
}

.content-header #block-icon-main-menu ul.submenu li {
  border-bottom: 1px solid #a3a7a9;
  float: none;
  padding-left: 15px;
  padding-right: 15px;
  width: 200px;
}

.content-header #block-icon-main-menu ul.submenu li:hover {
  background-color: #484c55;
}

.content-header #block-icon-main-menu ul.submenu li a {
  line-height: 35px;
  padding: 10px 0;
}

.content-header #block-icon-main-menu ul.submenu li a:hover {
  border-bottom: none;
}

.content-header .burger {
  background: transparent url("../images/icon-burger.png") no-repeat;
  background-size: 100%;
  color: #fff;
  height: 30px;
  position: absolute;
  right: 15px;
  top: 13px;
  width: 35px;
}

.content-header .burger.active {
  background: transparent url("../images/icon-burger-active.png") no-repeat;
  background-size: 100%;
}

.content-header .mobile-menu {
  background: rgba(40, 40, 40, 0.9);
  display: none;
  height: 100%;
  position: fixed;
  right: 0;
  text-align: left;
  top: 50px;
  width: 100%;
  z-index: 200;
  /* to fix style guide */
  min-height: 200px;
  position: absolute;
}

.content-header .mobile-menu ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.content-header .mobile-menu ul li a {
  border-bottom: 2px solid #fff;
  color: #fff;
  display: block;
  font-size: 0.9em;
  margin: 0 20px;
  padding: 10px;
  text-decoration: none;
}

.content-header .mobile-menu ul li a.is-active {
  background: black;
}

.content-header .mobile-menu ul li li a {
  padding: 10px 10px 10px 20px;
}

.content-header .mobile-menu.menu > li:last-of-type {
  margin-bottom: 20px;
}

.content-header .mobile-menu.menu > li.has-submenu > a:after {
  content: '\f107';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-left: 5px;
}

.content-header .mobile-menu .submenu {
  display: none;
}

.content-header .mobile-menu .submenu.visible {
  display: block;
}

.content-header .visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal;
}

.content-header .clearfix::after {
  clear: both;
  content: "";
  display: table;
}

.content-header .hidden-mobile {
  display: block;
}

@media (min-width: 960px) {
  .content-header .hidden-mobile {
    display: none !important;
  }
}

.content-header .noscroll {
  overflow: hidden;
}

/* Main nav with Login */
.login {
  float: right;
  display: block;
  background: #d71b36;
  color: #fff;
  text-decoration: none;
  margin-top: 14px;
  padding: 4px 15px;
  border-radius: 15px;
  margin-right: 5px;
  font-size: 0.8em;
}

.login:hover {
  text-decoration: none;
  color: #fff;
  background: #484c55;
}

@media (max-width: 959px) {
  .login {
    margin-right: 60px;
  }
}

header .profile {
  float: right;
  display: inline-block;
  line-height: 50px;
  margin-right: 10px;
}

@media (max-width: 959px) {
  header .profile {
    display: none;
  }
}

header .profile img {
  width: 28px;
  height: 28px;
}

.mobile-container .login {
  margin-right: 60px;
}

.nav-secondary a {
  color: #484c55;
  text-decoration: none;
  margin: 0 30px;
  padding: 10px 0 3px;
  font-weight: 500;
  font-size: 0.9em;
}

.nav-secondary a.active {
  border-bottom: 2px solid #d71b36;
}

/* selectpicker */
.bootstrap-select.alternative.btn-group .bs-searchbox input.form-control {
  padding-left: 35px;
}

.bootstrap-select.alternative.btn-group .bs-searchbox:after {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  text-decoration: inherit;
  position: absolute;
  font-size: 20px;
  color: #000;
  top: 17px;
  left: 20px;
  z-index: 1;
}

.bootstrap-select.alternative.btn-group .dropdown-menu li {
  padding-left: 30px;
}

.bootstrap-select.alternative.btn-group .dropdown-menu li a:after {
  content: "\f0c8";
  font-family: 'Font Awesome 5 Free';
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  text-decoration: inherit;
  position: absolute;
  font-size: 20px;
  color: #ccc;
  top: 2px;
  left: 10px;
  z-index: 1;
}

.bootstrap-select.alternative.btn-group .dropdown-menu li.selected {
  background: none;
}

.bootstrap-select.alternative.btn-group .dropdown-menu li.selected a:after {
  content: "\f14a";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  text-decoration: inherit;
  position: absolute;
  font-size: 20px;
  color: #d71b36;
  top: 2px;
  left: 10px;
  z-index: 1;
}

/* Tooltips */
.tooltip-inner {
  background-color: #33647f;
  padding: 10px;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before, .tooltip.tooltip-top .tooltip-inner::before {
  border-top-color: #33647f;
}

.tooltip.bs-tether-element-attached-left .tooltip-inner::before, .tooltip.tooltip-right .tooltip-inner::before {
  border-right-color: #33647f;
}

.tooltip.bs-tether-element-attached-top .tooltip-inner::before, .tooltip.tooltip-bottom .tooltip-inner::before {
  border-bottom-color: #33647f;
}

.tooltip.bs-tether-element-attached-right .tooltip-inner::before, .tooltip.tooltip-left .tooltip-inner::before {
  border-left-color: #33647f;
}

.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}

/* Error Indicator */
.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .form-check-label,
.has-danger .form-check-inline,
.has-danger .custom-control {
  color: red;
}

.has-danger .form-control {
  border-color: red;
}

/* Loading Indicator */
.loader {
  -webkit-animation: load 1.1s infinite ease;
  animation: load 1.1s infinite ease;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  height: 1em;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  width: 1em;
}

@-webkit-keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #d71b36, 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.5), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.7), 1.8em -1.8em 0 0em #d71b36, 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.5), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.7), 2.5em 0em 0 0em #d71b36, 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.5), 2.5em 0em 0 0em rgba(215, 27, 54, 0.7), 1.75em 1.75em 0 0em #d71b36, 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.5), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.7), 0em 2.5em 0 0em #d71b36, -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.5), 0em 2.5em 0 0em rgba(215, 27, 54, 0.7), -1.8em 1.8em 0 0em #d71b36, -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.5), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.7), -2.6em 0em 0 0em #d71b36, -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.5), -2.6em 0em 0 0em rgba(215, 27, 54, 0.7), -1.8em -1.8em 0 0em #d71b36;
  }
}

@keyframes load {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #d71b36, 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.5), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.7), 1.8em -1.8em 0 0em #d71b36, 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.5), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.7), 2.5em 0em 0 0em #d71b36, 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.5), 2.5em 0em 0 0em rgba(215, 27, 54, 0.7), 1.75em 1.75em 0 0em #d71b36, 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.5), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.7), 0em 2.5em 0 0em #d71b36, -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.2), -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.5), 0em 2.5em 0 0em rgba(215, 27, 54, 0.7), -1.8em 1.8em 0 0em #d71b36, -2.6em 0em 0 0em rgba(215, 27, 54, 0.2), -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.5), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.7), -2.6em 0em 0 0em #d71b36, -1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(215, 27, 54, 0.2), 1.8em -1.8em 0 0em rgba(215, 27, 54, 0.2), 2.5em 0em 0 0em rgba(215, 27, 54, 0.2), 1.75em 1.75em 0 0em rgba(215, 27, 54, 0.2), 0em 2.5em 0 0em rgba(215, 27, 54, 0.2), -1.8em 1.8em 0 0em rgba(215, 27, 54, 0.5), -2.6em 0em 0 0em rgba(215, 27, 54, 0.7), -1.8em -1.8em 0 0em #d71b36;
  }
}

/* Login */
.login-wrapper {
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0 auto;
  max-width: 800px;
}

.login-wrapper .login-header {
  background: #d71b36;
  color: #fff;
  text-align: center;
  font-size: 24px;
  padding: 15px;
}

.login-wrapper .login-body {
  padding: 40px 70px 20px;
}

@media (max-width: 959px) {
  .login-wrapper .login-body {
    padding: 40px 20px 20px;
  }
}

.login-wrapper .login-body label a {
  color: #33647f;
  padding-left: 5px;
}

.login-wrapper .login-footer {
  font-size: 10px;
  padding: 20px;
}

.login-wrapper .login-forgot {
  margin-top: 15px;
}

.login-wrapper .login-forgot a {
  display: block;
  color: #33647f;
  margin-bottom: 10px;
  text-decoration: none;
}

.login-wrapper .login-forgot a:hover {
  text-decoration: underline;
}

/* Support Template */
.support-wrapper {
  border: 1px solid gray;
  border-radius: 5px;
  margin: 0 15px;
  width: calc(100% - 30px);
}

.support-wrapper .support-header {
  background: #ff4e00;
  color: #fff;
  text-align: center;
  font-size: 24px;
  padding: 15px;
}

.support-wrapper .support-body {
  padding: 20px 5px;
}

.support-wrapper .support-sidebar {
  font-size: 0.8em;
  border-top: 1px solid gray;
  padding-top: 20px;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .support-wrapper .support-sidebar {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
}

.support-wrapper .support-sidebar table {
  margin-top: 15px;
}

.support-wrapper .support-sidebar .support-info {
  margin-top: 30px;
}

.support-wrapper .support-sidebar h5 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.support-wrapper .support-sidebar .article {
  margin-top: 10px;
}

.support-wrapper .support-sidebar .article p {
  margin-bottom: 0;
}

/* Combination Dropdown */
.selectize-control.single .selectize-input {
  background: #fff;
  border: 1px solid #000;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  padding: 10px 17px;
  width: 287px;
}

.selectize-control.single .selectize-input::after {
  background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
  border: none;
  height: 30px;
  right: 0;
  top: 7px;
  width: 30px;
}

.selectize-control.single .selectize-input.input-active::after {
  background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
  border: none;
  height: 30px;
  right: 0;
  top: 7px;
  width: 30px;
}

.selectize-control.single .selectize-input.input-active.dropdown-active::after {
  background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
  border: none;
  margin-top: 0;
  right: 17px;
  top: 4px;
  transform: rotate(180deg);
}

.selectize-dropdown {
  color: #000;
  font-size: 14px;
}

.selectize-dropdown.single {
  border: 1px solid #000;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.selectize-dropdown [data-selectable],
.selectize-dropdown .optgroup-header {
  padding: 5px 8px  5px 17px;
}

.selectize-dropdown .active {
  background-color: #d4d4d4;
  color: #000;
}

.selectize-input {
  font-size: 14px;
}

.selectize-input .dropdown-active::before {
  background: #000;
}

.selectize-input input {
  font-size: 14px;
  width: 235px;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 287px;
}

.bootstrap-select.btn-group .btn.dropdown-toggle {
  background-color: #fff;
  border: 1px solid #000;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 40px;
  margin-top: 0;
  padding: 10px 17px;
  width: 287px;
}

.bootstrap-select.btn-group .btn.dropdown-toggle:focus, .bootstrap-select.btn-group .btn.dropdown-toggle:hover {
  background-color: #fff;
  outline: none !important;
}

.bootstrap-select.btn-group .btn.dropdown-toggle::after {
  background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
  border: none;
  display: block;
  height: 30px;
  position: absolute;
  right: 0;
  top: 7px;
  width: 30px;
}

.bootstrap-select.btn-group .btn.dropdown-toggle.bs-placeholder .filter-option {
  color: darkgrey;
}

.bootstrap-select.btn-group .btn.dropdown-toggle .filter-option {
  color: #000;
  display: block;
  font-size: 14px;
  position: absolute;
  text-transform: initial;
  width: 253px;
}

.bootstrap-select.btn-group .dropdown-menu {
  border: 1px solid #000;
  border-top: 0 none;
  color: #000;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 0;
}

.bootstrap-select.btn-group .dropdown-menu li {
  padding: 5px 8px 5px 17px;
}

.bootstrap-select.btn-group .dropdown-menu li.selected {
  background-color: #d4d4d4;
}

.bootstrap-select.btn-group .dropdown-menu li.hidden {
  display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a {
  color: #000;
  font-size: 14px;
  outline: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span {
  outline: none;
}

.bootstrap-select.btn-group .dropdown-menu.open .actions-btn {
  -webkit-border-radius: 0.2em;
  border-radius: 0.2em;
  -webkit-transition: none;
  transition: none;
  width: 48%;
}

.bootstrap-select.btn-group .dropdown-menu.open .actions-btn.bs-select-all {
  background: #33647f;
  border-color: #33647f;
  margin-right: 2%;
}

.bootstrap-select.btn-group .dropdown-menu.open .actions-btn.bs-select-all:hover {
  background: #1d3a49;
  border-color: #1d3a49;
}

.bootstrap-select.btn-group .dropdown-menu.open .actions-btn.bs-deselect-all {
  margin-left: 2%;
}

.bootstrap-select.btn-group.dropup .dropdown-menu.open {
  border-bottom: 0 none;
  border-top: 1px solid #000;
}

.bootstrap-select.btn-group.open .dropdown-toggle::after {
  background: url(../images/icon-arrow-down.svg) right 17px center no-repeat #fff;
  border: none;
  margin-top: 0;
  right: 17px;
  top: 4px;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Required Field Indicator */
.required {
  font-weight: bold;
}

.required::after {
  color: red;
  content: '*';
}

/* Calendar Date Picker */
.datepicker {
  border: 1px solid #000;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 40px;
  min-width: 287px;
  padding: 0 17px;
}

.datepicker:focus {
  outline: none;
}

.daterangepicker {
  border: 1px solid #000;
  margin-left: 17px;
}

.daterangepicker::before {
  border-bottom-color: #000;
}

.daterangepicker .daterangepicker_input .input-mini {
  border: 1px solid #000;
  color: #000;
  height: 30px;
  padding: 0 17px 0 27px;
}

.daterangepicker .daterangepicker_input .input-mini.active {
  border-color: #000;
}

.daterangepicker .daterangepicker_input i {
  top: 6px;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  background-color: #fff;
  border: 1px solid #000;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 30px;
}

.daterangepicker td.in-range {
  background-color: rgba(215, 27, 54, 0.1);
}

.daterangepicker td.active {
  background-color: #d71b36;
}

.daterangepicker td.active:hover {
  background-color: #d71b36;
}

.daterangepicker .ranges .range_inputs .btn-success {
  background-color: #d71b36;
  border-color: #d71b36;
  font-weight: 400;
  height: 30px;
  padding: 0 15px;
}

.daterangepicker .ranges .range_inputs .btn-success:hover {
  background-color: #5b0b17;
  border-color: #5b0b17;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.daterangepicker .ranges .range_inputs .btn-default {
  background-color: #fff;
  border-color: #000;
  color: #000;
  font-weight: 400;
  height: 30px;
  padding: 0 15px;
}

.daterangepicker .ranges .range_inputs .btn-default:hover {
  background-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

/* Toggle Button */
/* Two States Version */
.btn.btn-toggle {
  background-color: #fff;
  border: 1px solid #a3a7a9;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 30px;
  padding: 0;
  position: relative;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 52.5px;
}

.btn.btn-toggle::before {
  background: #ccc;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: '';
  height: 26px;
  left: 1px;
  position: absolute;
  top: 1px;
  -webkit-transition: left 0.2s linear;
  transition: left 0.2s linear;
  width: 26px;
}

.btn.btn-toggle.mobile {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  height: 40px;
  width: 70px;
}

.btn.btn-toggle.mobile::before {
  height: 36px;
  width: 36px;
}

.btn.btn-toggle.mobile.active::before {
  left: 31px;
}

.btn.btn-toggle.active {
  background-color: #d71b36;
  border-color: #d71b36;
}

.btn.btn-toggle.active::before {
  left: 23px;
}

.btn.btn-toggle.active:hover {
  box-shadow: none;
}

.btn.btn-toggle.active:focus {
  outline: none;
}

.btn.btn-toggle:hover {
  box-shadow: none;
}

.btn.btn-toggle:focus, .btn.btn-toggle.focus {
  outline: none;
}

/* Toggle Alternative Version */
.toggle-buttons.alternative label.active {
  font-weight: 700;
}

.toggle-buttons.alternative .btn.btn-toggle {
  background-color: #33647f;
}

.toggle-buttons.alternative .btn.btn-toggle::before {
  background: #FFFFFF;
}

.toggle-buttons.alternative .btn.btn-toggle.active {
  background-color: #d71b36;
  border-color: #d71b36;
}

/* Three States Version */
input[type=range].range-toggle {
  -webkit-appearance: none;
  background: transparent;
  height: 30px;
  width: 78.75px;
}

input[type=range].range-toggle::-webkit-slider-thumb {
  animate: 0.2s;
  -webkit-appearance: none;
  background: #ccc;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  -webkit-transition: left 0.2s linear;
  transition: left 0.2s linear;
  width: 26px;
}

input[type=range].range-toggle::-moz-range-thumb {
  animate: 0.2s;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #ccc;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  -webkit-transition: left 0.2s linear;
  transition: left 0.2s linear;
  width: 26px;
}

input[type=range].range-toggle::-ms-thumb {
  animate: 0.2s;
  -webkit-appearance: none;
  background: #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  -webkit-transition: left 0.2s linear;
  transition: left 0.2s linear;
  width: 26px;
}

input[type=range].range-toggle::-webkit-slider-runnable-track {
  animate: 0.2s;
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  border-radius: 15px;
  cursor: pointer;
  height: 30px;
  padding: 1px;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 100%;
}

input[type=range].range-toggle::-moz-range-track {
  animate: 0.2s;
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  border-radius: 15px;
  cursor: pointer;
  height: 30px;
  padding: 1px;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 100%;
}

input[type=range].range-toggle::-ms-track {
  animate: 0.2s;
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  border-radius: 15px;
  cursor: pointer;
  height: 30px;
  padding: 1px;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 100%;
}

input[type=range].range-toggle::-ms-fill-lower {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
}

input[type=range].range-toggle::-ms-fill-upper {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
}

input[type=range].range-toggle, input[type=range].range-toggle:focus, input[type=range].range-toggle:active {
  border: 0;
  outline: none;
}

input[type=range].range-toggle::-moz-focus-inner, input[type=range].range-toggle:-moz-focusring, input[type=range].range-toggle::-moz-focus-outer {
  border: 0;
  outline: none;
}

input[type=range].range-toggle:focus::-webkit-slider-runnable-track {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  outline: none;
}

input[type=range].range-toggle:focus::-ms-fill-lower {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  outline: none;
}

input[type=range].range-toggle:focus::-ms-fill-upper {
  background-color: #a3a7a9;
  border: 1px solid #a3a7a9;
  outline: none;
}

input[type=range].range-toggle.range-false::-webkit-slider-runnable-track {
  background-color: #fff;
  border-color: #a3a7a9;
}

input[type=range].range-toggle.range-false::-moz-range-track {
  background-color: #fff;
  border-color: #a3a7a9;
}

input[type=range].range-toggle.range-false::-ms-track {
  background-color: #fff;
  border-color: #a3a7a9;
}

input[type=range].range-toggle.range-neutral::-webkit-slider-runnable-track {
  background-color: #33647f;
  border-color: #33647f;
}

input[type=range].range-toggle.range-neutral::-moz-range-track {
  background-color: #33647f;
  border-color: #a3a7a9;
}

input[type=range].range-toggle.range-neutral::-ms-track {
  background-color: #33647f;
  border-color: #33647f;
}

input[type=range].range-toggle.range-true::-webkit-slider-runnable-track {
  background-color: #d71b36;
  border-color: #d71b36;
}

input[type=range].range-toggle.range-true::-moz-range-track {
  background-color: #d71b36;
  border-color: #d71b36;
}

input[type=range].range-toggle.range-true::-ms-track {
  background-color: #d71b36;
  border-color: #d71b36;
}

input[type=range].range-toggle.mobile {
  height: 40px;
  width: 105px;
}

input[type=range].range-toggle.mobile::-webkit-slider-thumb {
  height: 36px;
  width: 36px;
}

input[type=range].range-toggle.mobile::-moz-range-thumb {
  height: 36px;
  width: 36px;
}

input[type=range].range-toggle.mobile::-ms-thumb {
  height: 36px;
  width: 36px;
}

input[type=range].range-toggle.mobile::-webkit-slider-runnable-track {
  border-radius: 20px;
  height: 40px;
}

input[type=range].range-toggle.mobile::-moz-range-track {
  border-radius: 20px;
  height: 40px;
}

input[type=range].range-toggle.mobile::-ms-track {
  border-radius: 20px;
  height: 40px;
}

/* File Upload Button */
.form-control-file {
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0.1px;
  z-index: -1;
}

.form-control-file + label {
  background-color: #fff;
  border: 1px solid #d71b36;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  color: #d71b36;
  cursor: pointer;
  display: table;
  font-size: 16px;
  font-weight: normal;
  padding: 10px 30px;
}

.form-control-file + label * {
  pointer-events: none;
}

.form-control-file + label svg {
  fill: currentColor;
  height: 1em;
  margin-right: 0.25em;
  margin-top: -0.25em;
  vertical-align: middle;
  width: 1em;
}

.form-control-file + label:hover {
  border-color: #5b0b17;
  color: #5b0b17;
}

.form-control-file:focus + .form-control-file-label {
  border-color: #5b0b17;
  color: #5b0b17;
}
